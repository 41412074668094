import { createRouter, createWebHistory } from 'vue-router';

import HomePage from '@/views/home-page.vue';
import GlobalGallery from '@/views/global-gallery.vue';
import SelfAbout from '@/views/self-about.vue';
import PageNotFound from '@/views/page-not-found.vue'

const routes = [
    { path: '/', meta:{ title: "我的技术分享" }, component: HomePage },
    { path: '/global-gallery', meta: { title: "ID Server - 画廊" }, component: GlobalGallery },
    { path: '/about', meta: { title: "ID Server - 关于" }, component: SelfAbout },
    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
    if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }
    next()  //执行进入路由，如果不写就不会进入目标页
})

export default router;