<template>
  <a
    href="#"
    class="badge-link"
    :class="{ 'selected': selected }"
  >
    <div class="badge-content">
      <span :style="leftStyles" class="left">{{ leftText }}</span>
      <span :style="rightStyles" class="right">{{ rightText }}</span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'TitleBadge',
  props: {
    leftText: {
      type: String,
      required: true
    },
    rightText: {
      type: String,
      required: true
    },
    fontSize: {
      type: Number,
      default: 12
    },
    selected: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#a0b181, #57b049'
    }
  },
  methods: {

  },
  computed: {
    leftStyles() {
      return {
        fontSize: `${this.fontSize}px`,
        backgroundImage: 'linear-gradient(#606060, #6c6c6c)'
      };
    },
    rightStyles() {
      return {
        fontSize: `${this.fontSize}px`,
        backgroundImage: `linear-gradient(${this.color})`
      };
    }
  }
}
</script>

<style scoped>
a.badge-link {
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  margin-right: 6px;
}

a.selected {
  background-color: #ccc;
  cursor: pointer;
}

a:hover {
  transform: translateY(-3px);
}

.badge-content {
  display: flex;
  justify-content: space-between;
}

.left {
  padding: 8px 10px 8px 18px;
  background-color: #222222;
  color: #ffffff;
  //background-image: linear-gradient(#606060, #6c6c6c);
  border-radius: 4px 0 0 4px;
}

.right {
  padding: 8px 18px 8px 10px;
  background-color: green;
  color: #ffffff;
  //background-image: linear-gradient(#a0b181, #57b049);
  border-radius: 0 4px 4px 0;
}
</style>
