<template>
    <div class="gallery-page">
      <div class="tag-rows">
        <span :class="tag.id === selectedTag ? 'tag-item-selected tag-item' : 'tag-item'" v-for="tag in tags" :key="tag" @click="onTagSelectEvent(tag.id)">{{ tag.title }}</span>
      </div>
<!--      <div class="data-row">-->
<!--          <div class="data-cell" v-for="item in displayImages" v-bind:key="item">-->
<!--              <div class="data-cell-title">{{ item.title }}</div>-->
<!--              <div>-->
<!--                  <img-->
<!--                      style="width: 100%; border-radius: 16px;"-->
<!--                      :src="item.url"-->
<!--                  />-->
<!--              </div>-->
<!--          </div>-->
<!--      </div>-->

      <div class="gallery" ref="gallery" @scroll="loadImages">
        <div
            v-for="(image, index) in images"
            :key="index"
            class="image-container"
            :style="{ width: containerWidth + 'px', height: image.height }"
            @mouseover="showDetails(index)"
            @mouseleave="hideDetails(index)"
        >
          <img :src="image.src" :style="{ width: containerWidth + 'px' }" @load="setImageSize(image)" />
          <div class="tag">{{ image.tag }}</div>
          <div class="recommendation">{{ image.recommendation }}</div>

          <div :style="{ width: containerWidth + 'px' }" :class="{ 'expanded-details': expandedIndex === index }" class="details">
            <div class="title">title: 测试</div>
            <div class="info">
              <div class="author">JK</div>
              <div class="createTimestamp">2021-03-05</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue"

const selectedTag = ref(1)
const displayImages = ref([])

const tags = ref([{
  'id': 1,
  'title': '真人'
}, {
  'id': 2,
  'title': '国风'
}, {
  'id': 3,
  'title': '动漫'
}, {
  'id': 4,
  'title': '建筑'
}])



const gallery = ref(null);

onMounted(() => {
  fetchData()

  loadImages()

  console.log(gallery.value)

  window.addEventListener('resize', updateContainerWidth);
})

const onTagSelectEvent = (tagId) => {
  const selectedTags = tags.value.filter((someTag) => someTag.id === tagId)
  selectedTag.value = selectedTags[0].id
}

watch(selectedTag, async (newTagId, oldTagId) => {
  console.log(`${newTagId} ${oldTagId}`)
  fetchData()
})

function fetchData() {
  const imageGroups = [{
    'id': 1,
    'tags': [1, 2],
    'items': [{
      'title': '图片1',
      'url': 'https://assets.idserver.vip/0/1.png'
    }, {
      'title': '图片2',
      'url': 'https://assets.idserver.vip/0/1.png'
    }, {
      'title': '图片3',
      'url': 'https://assets.idserver.vip/0/1.png'
    }, {
      'title': '图片4',
      'url': 'https://assets.idserver.vip/0/1.png'
    }]
  }]

  let groups = imageGroups.filter((group) => group.tags.includes(selectedTag.value))

  if (groups.length > 0) displayImages.value = groups[0]['items']
  else displayImages.value = []
}

const images = ref([]); // 图片数据
const itemsPerLine = 7;
const expandedIndex = ref(null); // 记录展开详情的图片索引
const containerWidth = ref(window.innerWidth / itemsPerLine); // 图片容器宽度

function loadImages() {
  const newImages = fetchImageData()
  images.value = images.value.concat(newImages); // 将新加载的图片数据合并到原始数组中
}

function setImageSize(image) {
  // 等比缩放图片尺寸以适应布局
  const img = new Image();
  img.src = image.src;
  img.onload = () => {
    const aspectRatio = img.width / img.height;
    let height = containerWidth.value / aspectRatio;
    image.height = `${height}px`;
  };
}

function updateContainerWidth() {
  containerWidth.value = window.innerWidth / itemsPerLine;
}

// async
function fetchImageData() {
  return [{
    'src': 'https://assets.idserver.vip/0/avatar/avatar-0_512_512.png',
    'tag': '测试',
    'recommendation': '推荐'
  }, {
    'src': 'https://assets.idserver.vip/add_model_after.png',
    'tag': '测试',
    'recommendation': '推荐'
  }, {
    'src': 'https://assets.idserver.vip/0/avatar/avatar-4_512_512.png',
    'tag': '测试',
    'recommendation': '推荐'
  }, {
    'src': 'https://assets.idserver.vip/0/avatar/avatar-3_512_512.png',
    'tag': '测试',
    'recommendation': '推荐'
  }, {
    'src': 'https://assets.idserver.vip/0/mural/mural-6_768_512.png',
    'tag': '测试',
    'recommendation': '推荐'
  }, {
    'src': 'https://assets.idserver.vip/0/mural/mural-1_768_512.png',
    'tag': '测试',
    'recommendation': '推荐'
  }, {
    'src': 'https://assets.idserver.vip/0/mural/mural-5_768_512.png',
    'tag': '测试',
    'recommendation': '推荐'
  }, {
    'src': 'https://assets.idserver.vip/0/mural/mural-3_768_512.png',
    'tag': '测试',
    'recommendation': '推荐'
  }, {
    'src': 'https://assets.idserver.vip/0/1.png',
    'tag': '测试',
    'recommendation': '推荐'
  }]

  // try {
  //   const response = await fetch(apiUrl);
  //   if (!response.ok) {
  //     throw new Error('Network response was not ok.');
  //   }
  //   const data = await response.json();
  //   return data;
  // } catch (error) {
  //   console.error('There was a problem with the fetch operation:', error);
  //   return [];
  // }
}

function showDetails(index) {
  expandedIndex.value = index;
}

function hideDetails() {
  expandedIndex.value = null;
}
</script>

<style>
.gallery-page {
  background-color: #222;
  height: 100%;
  padding: 10px;
}

  .tag-rows {
    padding: 0 10px;
    margin-bottom: 16px;
  }
    .tag-item {
      color: #ffffff;
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 18px;
      margin-right: 10px;
    }
    .tag-item:hover {
      cursor: pointer;
      background: #3c3c3c;
    }
    .tag-item-selected {
      background: #3c3c3c;
    }

.gallery {
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
}

.image-container {
  position: relative;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
}

.tag {
  position: absolute;
  top: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  border-radius: 0 4px 4px 0;
  padding: 4px 8px 4px 8px;
}

.recommendation {
  position: absolute;
  top: 0;
  right: 5px;
  color: aqua;
  font-size: 14px;
  background-color: #222222;
  padding: 5px 10px;
  border-radius: 0 0 4px 4px;
}

.title {
  display: flex;
  justify-content: start;
  padding: 10px;
  color: #ffffff;
}

.info {
  display: flex;
  padding: 0 10px 10px 10px;
}
  .author {
    display: flex;
    flex: 1;
    justify-content: start;
    color: #ffffff;
    font-size: 12px;
  }
  .createTimestamp {
    display: flex;
    flex: 1;
    justify-content: end;
    color: #dddddd;
    font-size: 12px;
  }

.details {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  border-radius: 0 0 4px 4px;
}

.expanded-details {
  display: block;
  height: auto;
}
</style>