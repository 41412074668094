<template>
    <header class="header">
      <div class="logo">ID server - AIGC作品集</div>
      <nav class="menu">
        <ul>
          <li class="btn one" @click="home">
            首页
          </li>
          <li class="btn two" @click="gallery">
            画廊
          </li>
          <li class="btn three" @click="about">
            关于
          </li>
        </ul>
      </nav>
    </header>
  </template>
  
  <script>
  // import router from "@/router";
  export default {
    name: "FrameHeader",

    setup() {

    },

    methods: {
      home() {
        this.$router.push('/');
      },
      gallery() {
        this.$router.push('/global-gallery');
      },
      about() {
        this.$router.push('/about')
      }
    }
  };


  </script>
  
  <style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222; /* Customize the background color */
    color: #fff; /* Customize the text color */
    padding: 12px 20px;
  }
  
  .logo {
    font-size: 24px; /* Customize the logo font size */
  }
  
  .menu ul {
    list-style: none;
    display: flex;
  }
  
  .menu li {
    margin-right: 20px;
  }
  
  .menu a {
    color: #fff; /* Customize the link color */
    text-decoration: none;
  }

.btn {
  background-color: transparent;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 300;
}

.btn:hover {
  color: white;
  border: 0;
}

.one {
  color: #ffffff;
}

.two {
  color: #ffffff;
}

.three {
  color: #ffffff;
}

.one:hover {
  background-color: #2f86a0;
  -webkit-box-shadow: 10px 10px 99px 6px rgb(24, 114, 141);
  -moz-box-shadow: 10px 10px 99px 6px rgb(26, 134, 167);
  box-shadow: 10px 10px 99px 6px rgb(26, 123, 153);
  cursor: pointer;
}

.two:hover {
  background-color: #cc6225;
  -webkit-box-shadow: 10px 10px 99px 6px rgb(172, 100, 18);
  -moz-box-shadow: 10px 10px 99px 6px rgb(168, 104, 7);
  box-shadow: 10px 10px 99px 6px rgb(172, 108, 13);
  cursor: pointer;
}

.three:hover {
  background-color: #486b0c;
  -webkit-box-shadow: 10px 10px 99px 6px rgb(103, 146, 29);
  -moz-box-shadow: 10px 10px 99px 6px rgb(81, 121, 14);
  box-shadow: 10px 10px 99px 6px rgb(98, 146, 14);
  cursor: pointer;
}
  </style>