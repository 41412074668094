<template>
    <main class="main-content">
      <div class="group-row">
        <div class="group-title">
          <TitleBadge
              left-text="时尚画布"
              right-text="Fashion"
              font-size="20"
          />
        </div>

        <div class="group-content">
          <div class="data-row" v-for="group in groupItems" v-bind:key="group">
            <div class="data-cell" :data-aos="group.animte_aos" v-for="item in group.items" v-bind:key="item">
              <div class="cell-image">
                <ImgComparisonSlider hover="hover">
                  <!-- eslint-disable -->
                  <img
                      slot="first"
                      style="width: 100%; border-radius: 16px;"
                      :src="item.before"
                  />

                  <img
                      slot="second"
                      style="width: 100%; border-radius: 16px"
                      :src="item.after"
                  />
                  <!-- eslint-enable -->
                </ImgComparisonSlider>
              </div>
              <div class="cell-title">
                <TagBadge :text="item.title" font-size="20" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="group-row">
        <div class="group-title">
          <TitleBadge
              left-text="视觉设计"
              right-text="Vision Design"
              font-size="20"
              color="#ff76E2, #7827ef"
          />
        </div>
        <div class="group-content">
          <template v-for="visualGrp in visualGroups" v-bind:key="visualGrp">
            <div class="group-title">
              <TagBadge :text="visualGrp.groupName" font-size="20" />
            </div>
            <ImageSlider :images="visualGrp.items" :interval="3000" />

            <br />
            <br />
          </template>
        </div>
      </div>

      <div class="group-row">
        <div class="group-title">
          <TitleBadge
              left-text="风格生成"
              right-text="Style Generator"
              font-size="20"
              color="#553366, #18279a"
          />
        </div>

        <div class="group-content">
          <FilmSlider :imageGroups="styleGroups" />
        </div>
      </div>

    </main>
  </template>
  
  <script>

  import { ImgComparisonSlider } from '@img-comparison-slider/vue';
  import FilmSlider from "@/components/FilmSlider.vue";
  import ImageSlider from "@/components/ImageSlider.vue";
  import TagBadge from "@/components/TagBadge.vue";
  import TitleBadge from "@/components/TitleBadge.vue";

  import groupItems from '@/assets/fashion_groups.json'
  import visualGroups from '@/assets/visual_groups.json'
  import styleGroups from '@/assets/style_groups.json'

  export default {
    name: "MainFrame",
    setup() {
    },
    data() {
        return {
          groupItems: groupItems,
          visualGroups: visualGroups,
          styleGroups: styleGroups
        }
    },
    mounted() {
    },
    computed: {

    },
    methods: {
    },
    components: { 
      ImgComparisonSlider,
      FilmSlider,
      ImageSlider,
      TagBadge,
      TitleBadge
    },
  };
  
  </script>
  
  <style scoped>
  .main-content {
    background-color: #222;
    height: 100%;
    padding: 10px;
  }

  .group-row {
    margin-top: 20px;
    border-bottom: 1px solid #444444;
  }

  .data-row {
    padding: 0 0 50px 0;
    display: flex;
  }
  .data-cell {
    flex: 1;
  }
  
  .cell-title {
    text-align: center; 
    padding: 10px;
  }
    .cell-title span {
      font-size: 16px;
      color:rgb(240, 239, 239);
      border-left: 3px solid rgb(121, 121, 121);
      border-right: 3px solid rgb(121, 121, 121);
      padding: 0 24px;
      margin: 10px;
    }

  .cell-image {
    width: 100%;
    height: 96%;
    display: flex;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
  }

  .group-title {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 1px;
    background-color: #222;
    text-align: left;
    font-size: 16px;
    padding: 0 15px 15px 5px;
  }
  .group-content {
    margin-top: 50px;
  }
    .group-title {
      display: flex;
      justify-content: center;
    }
  </style>