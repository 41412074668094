<template>
  <div class="image-slider">
    <div class="original-image">
      <img :src="currentImage.url" :alt="currentImage.alt" />
    </div>
    <div class="thumbnails">
      <div class="thumbnail" v-for="(image, index) in images" :key="index" :class="{ 'selected': index === currentImageIndex }">
        <img :src="image.url" :alt="image.alt" @click="activateImage(index)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    interval: {
      type: Number,
      required: true,
      defaults: 3000
    }
  },
  data() {
    return {
      currentImageIndex: 0,
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
  },
  mounted() {
    setInterval(this.nextImage, this.interval); // Change image every 1 second
  },
  methods: {
    nextImage() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    },
    activateImage(index) {
      this.currentImageIndex = index;
    },
  },
};
</script>

<style scoped>
/* Styles for the component */
.image-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Add styles as needed */
}

.original-image img {
  width: 100%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
}

.thumbnails {
  display: flex;
  /* Add styles as needed */
}

.thumbnail {
  margin: 5px; /* Adjust spacing between thumbnails */
  cursor: pointer;
  /* Add styles as needed */
}

.thumbnail img {
  width: 50px; /* Adjust thumbnail image size */
  height: auto; /* Maintain aspect ratio */
  border-radius: 6px;
}

.selected {
  border: 4px solid #ffffff; /* You can adjust the border properties and color */
  border-radius: 2px;
}
</style>