<template>
  <a
    href="#"
    class="tag-link"
    :class="{ 'selected': selected }"
  >
    <div class="tag-content">
      <span :style="styles" class="tag">{{ text }}</span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'TagBadge',
  props: {
    text: {
      type: String,
      required: true,
      default: "测试标签"
    },
    fontSize: {
      type: Number,
      default: 12
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {

  },
  computed: {
    styles() {
      return {
        fontSize: `${this.fontSize}px`,
        backgroundImage: 'linear-gradient(#101010, #2f2f2f)'
      };
    }
  }
}
</script>

<style scoped>
a.tag-link {
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  margin-right: 6px;
}

a.selected {
  background-color: #ccc;
  cursor: pointer;
}

a:hover {
  transform: translateY(-3px);
}

.tag-content {
  display: flex;
  justify-content: space-between;
}

.tag {
  padding: 8px 36px;
  background-color: #222222;
  color: #ffffff;
  //background-image: linear-gradient(#606060, #6c6c6c);
  border-radius: 4px;
}
</style>
