<template>
  <div class="film-strip">
    <div class="film">
      <div class="sprockets">
        <div class="sprocket-hole" v-for="index in numSprockets" :key="index">
          <div class="sprocket-pot"></div>
        </div>
      </div>
      <div class="photos-container">
        <div class="film-roll" :style="{ transform: `translateX(${offset}px)` }" ref="filmRoll">
          <div v-for="imgGroup in imageGroups" :key="imgGroup" class="photo-container">
            <img :src="imgGroup.url" :alt="imgGroup.title" class="photo" />
            <div class="photo-title">{{ imgGroup.title }}</div>
          </div>
        </div>
      </div>
      <div class="sprockets">
        <div class="sprocket-hole" v-for="index in numSprockets" :key="index">
          <div class="sprocket-pot"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps } from 'vue'

defineProps({
  imageGroups: {
    type: Array,
    required: true
  }
})

const offset = ref(0)
const numSprockets = ref(10)
const filmRoll = ref(null)

const startAnimation = () => {
  const rollWidth = filmRoll.value.scrollWidth;
  const photosWidth = filmRoll.value.clientWidth;

  const animate = () => {
    requestAnimationFrame(() => {
      offset.value -= 1;
      if (Math.abs(offset.value) >= photosWidth) {
        offset.value += photosWidth;
      }
      if (Math.abs(offset.value) >= rollWidth) {
        offset.value = 0;
      }
      animate();
    });
  };

  animate();
}

onMounted(() => startAnimation())
</script>

<style>
.film-strip {
  width: 100%;
  overflow: hidden;
}

.film {
  background-color: #222; /* Semi-transparent brown color for the film */
  padding: 20px; /* Padding for the film */
}

.sprockets {
  display: flex;
}

.sprocket-hole {
  flex: 1;
  display: flex;
  align-items: center;
}

.sprocket-pot {
  height: 18px;
  width: 16px;
  background-color: #999999; /* Color for sprocket holes */
  border-radius: 4px;
  margin-bottom: 4px; /* Spacing between sprocket holes */
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
}

.photos-container {
  overflow: hidden;
  position: relative;
  flex: 1;
  margin: 10px 5px;
}

.photo-container {
  position: relative;
  display: inline-block;
  margin-right: 20px; /* Adjust the spacing between photos */
}

.photo-title {
  position: absolute;
  bottom: 5px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 28px;
  padding: 10px;
  border-radius: 3px;
}

.film-roll {
  display: flex;
  transition: transform 0.3s ease; /* Adjust the transition duration and easing */
}

.photo {
  width: 300px; /* Adjust the width of your photos */
  height: auto;
  margin-right: 20px; /* Adjust the spacing between photos */
  border-radius: 10px;
}
</style>