<template>
    <div class="about-page">
        <div class="content">我们是一家专注以AIGC领域商业落地的公司</div>
    </div>
</template>
<script setup>

</script>

<style>
.about-page {
    background-color: #333;
}

.content {
    padding: 20px;
    color: aliceblue;
}
</style>