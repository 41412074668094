<template>
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-text">© 2023 JK</div>
        <div class="footer-link">
          <a class="beian-info" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2022018685号-1</a>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: "FrameFooter",
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #222; /* Customize the background color */
    color: #fff; /* Customize the text color */
    text-align: center;
    padding: 10px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-link {
    color: #fff; /* Customize the link color */
    text-decoration: none;

    .beian-info {
      color: #fff;
      text-decoration: none;
    }
  }
  </style>